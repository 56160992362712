import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/policy.css"
import {monthNames} from "../components/names"

const PrivacyPolicy = ({ data }) => {

    
  
    return (
        <>
        <SEO title="Privacy Policy" />
        <section className="privacy-policy--text">
        <h1>Privacy Policy</h1>
        <p className="opaque50">Senest opdateret {new Date(data.directusPrivacyPolicy.modified_on.replace(/-/g, '/')).getDate() + " " + monthNames[new Date(data.directusPrivacyPolicy.modified_on.replace(/-/g, '/')).getMonth()] + " " + new Date(data.directusPrivacyPolicy.modified_on.replace(/-/g, '/')).getFullYear()}</p>
        <div dangerouslySetInnerHTML={{__html:data.directusPrivacyPolicy.text}}></div>
        </section>
    </>
)}
export default PrivacyPolicy
export const query = graphql`
    query {
        directusPrivacyPolicy {
            text
            modified_on
        }
    }
`
